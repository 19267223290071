<template>
    <div class="register-box">
        <div v-if="step == 1">
            <div class="top-bar">
                注册开户信息填写
            </div>
            <div class="main-box">
                <div class="form-box">
                    <van-form @submit="handleSubmit">
                        <van-field v-model="from.mobile" name="手机号码" label="手机号码" placeholder="请输入手机号码"
                            :rules="[{ required: true, message: '' }, { validator: asyncMobileValidator, message: '手机号不合法' }]" />
                        <div class="btn" style="margin-top:10vh;">
                            <van-button round block type="info" native-type="submit" :disabled="isSubmiting"
                                :loading="isSubmiting">注册</van-button>
                        </div>
                    </van-form>
                    <div class="btn" style="margin-top:50px;">
                        <van-button round block type="default" @click="handleAcctValidator"
                            :loading="isSubmiting">已有账号，直接绑卡</van-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="result" v-if="step == 2">
            <div class="top-bar">
                开户结果信息
            </div>
            <div class="tips" v-if="submitRsult.state == 0">
                <img src="../../assets/icon/wait.svg" alt="">
                <p class="txt">开户中,请等待...</p>
            </div>
            <div class="tips" v-else>
                <img src="../../assets/icon/error.svg" alt="">
                <p class="txt">开户失败，请稍后再试</p>
            </div>
        </div>
    </div>
</template>
<script>
import { registerEntAcct, getRegisterEntAcct, registerMchAcct } from '@/api/api';
import { Dialog } from 'vant';
export default {
    name: "Register",
    data() {
        return {
            isSubmiting: false,
            from: {
                realName: "",
                mobile: "",
                idCardFrontUrl: "",
                idCardBackUrl: "",
                idCard: "",
                certValidDateRange: ""
            },
            idCardFrontUrlList: [],
            idCardBackUrlList: [],
            type: "",
            submitRsult: {},
            step: 1
        }
    },
    created() {
        let params = this.$route.query.from;
        this.type = this.$route.query.type;
        if (!params) {
            this.$router.push({ path: "/openaccount/register" }).catch(() => { });
        }
        this.from = JSON.parse(params);
    },
    methods: {
        toLink() {
            this.$router.push({ path: "/openaccount/binding" }).catch(() => { });
        },
        asyncMobileValidator(val) {
            return /^(?:(?:\+|00)86)?1(?:3[\d]|4[5-79]|5[0-35-9]|6[5-7]|7[0-8]|8[\d]|9[1589])\d{8}$/.test(val);
        },
        asyncFileValidator(val) {
            return val && val.length > 0;
        },
        handleSubmit() {
            this.isSubmiting = true;
            if (this.type == 'idCard') {
                //埋点！20240506
                if (!this.from.mchNo || this.from.mchNo.length < 5) {
                    Dialog.alert({
                        title: '提示',
                        message: `商户号【${this.from.mchNo}】不正确，请联系管理员`,
                        theme: 'round-button',
                    }).then(() => { });
                    this.isSubmiting = false
                    return;
                }
                registerEntAcct(this.from).then(res => {
                    this.isSubmiting = false
                    if (res) {
                        if (res.state == 1) {
                            this.$router.push({ path: "/openaccount/binding?id=" + res.id + "&idCard=" + this.from.idCard + "&regNo=" + res.regNo + "&type=" + res.type }).catch(() => { });
                        } else {
                            this.submitRsult = res;
                            this.step = 2;
                        }
                    }
                }).catch((err) => {
                    this.isSubmiting = false
                    Dialog.alert({
                        title: '提示',
                        message: err.msg,
                        theme: 'round-button',
                    }).then(() => { });
                })
            }
            if (this.type == 'scan') {
                registerMchAcct(this.from).then(res => {
                    this.isSubmiting = false
                    if (res) {
                        if (res.state == 1) {
                            this.$router.push({ path: "/openaccount/binding?id=" + res.id + "&idCard=" + this.from.idCard + "&regNo=" + res.regNo + "&type=" + res.type }).catch(() => { });
                        } else {
                            this.submitRsult = res;
                            this.step = 2;
                        }
                    }
                }).catch((err) => {
                    this.isSubmiting = false
                    Dialog.alert({
                        title: '提示',
                        message: err.msg,
                        theme: 'round-button',
                    }).then(() => { });
                })
            }
        },
        handleAcctValidator() {
            this.isSubmiting = true;
            getRegisterEntAcct({ idCard: this.from.idCard, mchNo: this.from.mchNo }).then(res => {
                this.isSubmiting = false;
                if (res.data) {
                    this.$router.push({ path: "/openaccount/binding?id=" + res.data.id + "&idCard=" + res.data.idCard + "&regNo=" + res.data.regNo + "&type=" + res.data.type }).catch(() => { });
                }
            }).catch((err) => {
                this.isSubmiting = false;
                Dialog.alert({
                    title: '提示',
                    message: err.msg,
                    theme: 'round-button',
                }).then(() => { });
            })
        }
    }
}
</script>
<style scoped>
.main-box {
    margin-top: 10vh;
}

.register-box {
    width: 100%;
    height: calc(100vh);
    margin: 0px;
    padding: 0px;
    overflow-y: scroll;

}

.register-box .top-bar {
    line-height: 2;
    font-size: 40px;
    padding: 20px;
    font-weight: 550;
    text-align: center;
}

.uploader {
    margin: 16px;
    text-align: left;
}

.uploader .tip-txt {
    font-size: 30px;
    line-height: 2;
}

::v-deep .van-cell__title.van-field__label,
::v-deep .van-cell__value.van-field__value {
    font-size: 30px;
    line-height: 3;
}

::v-deep .van-field__error-message {
    font-size: 30px;
}

::v-deep .van-uploader__upload {
    width: 30vw;
    height: 30vw;
}

::v-deep .van-uploader__wrapper,
::v-deep .van-uploader__wrapper .van-uploader__preview,
::v-deep .van-uploader__wrapper .van-uploader__preview .van-image {
    width: 30vw;
    height: 30vw;
}

::v-deep .van-uploader__preview-delete {
    width: 50px;
    height: 50px;
}

::v-deep .van-uploader__preview-delete .van-icon {
    font-size: 55px;
}

::v-deep .van-icon.van-icon-photograph.van-uploader__upload-icon {
    font-size: 70px;
}

::v-deep .van-button {
    font-size: 30px;
    height: 80px;
}

.btn {
    margin: 20px;
}

.tips {
    margin-top: 5vh;
    padding: 20px;
}

.tips .txt {
    font-size: 30px;
}
</style>